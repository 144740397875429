
import { mapGetters } from 'vuex'
export default {
    name: 'TermAndConditions',
    data: () => ({
        articleId: '',
        listExpandModel: {},
        expandHeaderModel: {},
    }),
    head() {
        return {
            title: this.getLocaleLang(this.content?.title, true),
            meta: [
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: this.getLocaleLang(this.content?.title), // This can be overridden by page-level meta tags.
                },
            ],
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.themes.settings.isMobile
        },
        ...mapGetters({
            detailArticles: 'cms/detailArticles',
            footerTermAndCondition: 'cms/footerTermAndCondition',
        }),
        loading() {
            return this.detailArticles.length === 0
        },
        content() {
            const { title, slug } = this.$route.params
            if (title && slug) {
                const findMatchArticleContent = this.detailArticles.find(
                    (el) => {
                        if (el.title) {
                            let isMatch = false
                            for (const key in el.title) {
                                if (key.includes('-slug')) {
                                    if (
                                        el.title[key]?.toLowerCase() ===
                                        title?.toLowerCase()
                                    ) {
                                        isMatch = true
                                    }
                                }
                            }
                            return isMatch
                        }
                        return false
                    }
                )

                if (!findMatchArticleContent) return {}

                const findMatchArticleContentItem =
                    findMatchArticleContent.items.find((el) => {
                        if (el.title) {
                            let isMatch = false
                            for (const key in el.title) {
                                if (key.includes('-slug')) {
                                    if (
                                        el.title[key]?.toLowerCase() ===
                                        slug?.toLowerCase()
                                    ) {
                                        isMatch = true
                                    }
                                }
                            }
                            return isMatch
                        }
                        return false
                    })
                if (!findMatchArticleContentItem) {
                    window.location.href = '/not-found'

                    return {}
                }

                return findMatchArticleContentItem
            }
            if (this.detailArticles.length) {
                const title = this.detailArticles[0]?.title
                const description = this.detailArticles[0].items[0].description
                return {
                    title,
                    description,
                }
            }
            return {}
        },
        lang() {
            return `${this.handleCookie('wl_locale')}-slug`
        },
    },
    watch: {
        // Set the expand list to be default value
        // Default value is gonna be the first of each main article and sub items in the article
        detailArticles(value) {
            if (value && value.length) {
                const { title, slug } = this.$route.params
                if (!title && !slug) {
                    const mainArticle = value[0]?.title
                    const subArticle = value[0]?.items[0]?.title

                    const title = mainArticle[this.lang]
                    const slug = subArticle[this.lang]
                    if (title && slug) {
                        this.articleId = slug
                        this.listExpandModel[title] = true
                    }

                    // For mobile Screen
                    this.expandHeaderModel = { 0: 0 }
                } else {
                    // Mobile Screen Case
                    this.setDefaultExpandForMobile(title, slug, value)
                }
            }
        },
    },
    created() {
        const { title, slug } = this.$route.params
        if (slug) this.articleId = slug
        if (title) this.listExpandModel[title] = true
    },
    mounted() {
        const { title, slug } = this.$route.params

        if (slug) this.articleId = slug
        if (title) this.listExpandModel[title] = true
        if (this.detailArticles) {
            this.setDefaultExpandForMobile(title, slug, this.detailArticles)
        }
    },
    destroyed() {
        this.$store.dispatch('detail/setCustomContent', {})
        if (this.clickHeaderTimeout) {
            clearTimeout(this.clickHeaderTimeout)
        }
    },
    methods: {
        setDetail(item, mainTitle) {
            const title = mainTitle[this.lang]
            const slug = item?.title[this.lang]
            this.$store.dispatch('detail/setCustomContent', item)
            this.$router.push({
                params: { title: title || '-', slug: slug || '-' },
            })
        },
        getPageYOffsetById(elementId) {
            const element = document.getElementById(elementId)
            if (element) {
                const rect = element.getBoundingClientRect()
                return rect.top + window.pageYOffset
            }
            return null // Element with the specified id not found
        },
        onClickHeader(headerId) {
            if (this.clickHeaderTimeout) {
                clearTimeout(this.clickHeaderTimeout)
            }
            this.clickHeaderTimeout = setTimeout(() => {
                const offset = this.getPageYOffsetById(headerId)
                window.scrollTo({
                    top: offset - 100 + 24,
                    behavior: 'smooth',
                })
            }, 450)
        },
        setDefaultExpandForMobile(title, slug, contentValue) {
            if (!title || !slug) return null
            function findMatch(el) {
                if (el.title) {
                    let isMatch = false
                    for (const key in el.title) {
                        if (key.includes('-slug')) {
                            if (
                                el.title[key]?.toLowerCase() ===
                                title?.toLowerCase()
                            )
                                isMatch = true
                        }
                    }
                    return isMatch
                }
            }
            const expandListIndex = contentValue.findIndex(findMatch)
            const findMatchArticleContent = contentValue.find(findMatch)
            if (findMatchArticleContent) {
                function findSubMatch(el) {
                    if (el.title) {
                        let isMatch = false
                        for (const key in el.title) {
                            if (key.includes('-slug')) {
                                if (
                                    el.title[key]?.toLowerCase() ===
                                    slug?.toLowerCase()
                                )
                                    isMatch = true
                            }
                        }
                        return isMatch
                    }
                }
                const expandSubListIndex =
                    findMatchArticleContent.items.findIndex(findSubMatch)
                const expandSubListItem =
                    findMatchArticleContent.items.find(findSubMatch)
                if (expandListIndex > -1) {
                    this.expandHeaderModel[expandListIndex] = expandSubListIndex
                }
                if (expandSubListItem) {
                    this.onClickHeader(`header-${expandSubListItem._id}`)
                }
            }
        },
    },
}
